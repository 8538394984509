import * as React from "react";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader/Loader";
import { Props, SFC } from "react";

export default function LoadingSpinner({ children }: Props<SFC>) {
  return (
    <Loader
      style={{ marginTop: "50px" }}
      active={true}
      inline="centered"
      content={children}
    />
  );
}
