import React, { useState } from "react";
import { User } from "./AppTypes";

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;

interface AppState {
  auth: {
    initialized: boolean; // Used to indicate if token restoration from localstorage has run, so that no hasty redirect is performed based on login state
    token: string;
    user: User | null;
  };
  gui: {
    showVauoidModal: boolean;
    appReloadRequested: boolean;
  };
}

type AppStateSetter = StateSetter<AppState>;
type ContextProps = [AppState, AppStateSetter];

const stateSetter: AppStateSetter = () => {};

export const defaultAuthState = {
  initialized: false,
  token: "",
  user: null,
};

const defaultAppState: AppState = {
  auth: defaultAuthState,
  gui: {
    showVauoidModal: false,
    appReloadRequested: false,
  },
};

const AppContext = React.createContext<ContextProps>([
  defaultAppState,
  stateSetter,
]);

const AppProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(defaultAppState);
  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
