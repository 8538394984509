import { parse as parseQueryString } from "qs";
import React, { useEffect } from "react";
import { Grid, Header, Image, Loader, Segment } from "semantic-ui-react";
import RedirectMessage from "../components/RedirectMessage";
import useAuth from "../useAuth";
import BigVodafoneLogo from "./200.png";

interface Props {
  redirect: string;
}

const LoginPage: React.SFC<Props> = ({ redirect }) => {
  const { restoreAuthFromLocalStorage, initialized } = useAuth();

  useEffect(() => {
    const query = parseQueryString(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (query.hasOwnProperty("jwt")) {
      restoreAuthFromLocalStorage(query.jwt as string);
    } else {
      restoreAuthFromLocalStorage();
    }
  }, []);

  if (initialized) {
    return (
      <RedirectMessage
        redirect={redirect}
        text="Authentifizierung nicht möglich. Du wirst weiter geleitet."
      />
    );
  }

  return (
    <Grid centered={true} style={{ paddingTop: "40px" }}>
      <Grid.Column width={4} />
      <Grid.Column width={4}>
        <Segment padded={true} raised={true}>
          <Image height="100px" centered={true} src={BigVodafoneLogo} />
          <Header as="h1" textAlign="center">
            Lead Sales Tool
          </Header>
          <Loader inline="centered" active={true}>
            Initialisiere Anwendung
          </Loader>
        </Segment>
      </Grid.Column>
      <Grid.Column width={4} />
    </Grid>
  );
};

export default LoginPage;
