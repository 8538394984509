import { groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Header from "semantic-ui-react/dist/commonjs/elements/Header/Header";
import { Offer } from "../AppTypes";
import OfferSessionCollection from "./OfferSessionCollection";

interface Props {
  offers: Offer[];
  onReload: () => void;
}

interface IGroupedOffers {
  [key: string]: Offer[];
}

const RecentOffers: React.FunctionComponent<Props> = ({ offers, onReload }) => {
  const [groupedOffers, setGroupedOffers] = useState<IGroupedOffers>({});
  useEffect(() => {
    setGroupedOffers(groupBy(offers, "session_id"));
  }, [offers]);

  return (
    <React.Fragment>
      <div className="clearfix">
        <Header floated="left">
          Deine erstellten effective Sellings der letzten 24h ohne
          Kundenzuordnung
        </Header>
        <Header floated="right">
          <Button icon="redo" content="Aktualisieren" onClick={onReload} />
        </Header>
      </div>
      {offers.length === 0 && (
        <p>
          Es wurden keine Empfehlungen für diesen Shop aus den vergangenen 24
          Stunden gefunden.
        </p>
      )}
      {offers.length > 0 &&
        Object.keys(groupedOffers).map((key) => (
          <OfferSessionCollection
            key={key}
            reload={onReload}
            offers={groupedOffers[key]}
          />
        ))}
    </React.Fragment>
  );
};

export default React.memo(RecentOffers);
