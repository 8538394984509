import React, { useState, useEffect } from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import InlineError from "../components/InlineError";
import useAuth from "../useAuth";
import useGui from "../useGui";

interface Props {
  loading: boolean;
  onSubmit: (voId: string) => void;
}

const VauoidForm: React.SFC<Props> = ({ onSubmit, loading }) => {
  const { user } = useAuth();
  const { hideVauoidModal } = useGui();
  const [vauoid, setVauoid] = useState(
    user && user.vo_id !== null ? user.vo_id : ""
  );
  const [validationMessage, setValidationMessage] = useState("");
  useEffect(() => {
    validate(vauoid);
  }, []);

  const validate = (value: React.SetStateAction<string>) => {
    setVauoid(value);
    if (value.length > 8 || value.length < 8) {
      setValidationMessage("Die Id sollte genau 8 Ziffern lang sein.");
    } else if (!/^[0-9]+$/.test(value as string)) {
      setValidationMessage("Die Id sollte nur aus Ziffern bestehen.");
    } else {
      setValidationMessage("");
    }
  };

  const onFormSubmit = () => {
    validate(vauoid);
    if (!validationMessage) {
      onSubmit(vauoid);
    }
  };

  return (
    <Form onSubmit={onFormSubmit} loading={loading}>
      Um Deine Leads zuordnen zu können, benötigen wir Deine VO ID. Du kannst
      die ID unter dem Menüpunkt Account jederzeit ändern.
      <Form.Field width={6}>
        <label htmlFor="vo_id">VO ID</label>
        <Input
          name="vo_id"
          onChange={(e, { value }) => validate(value)}
          value={vauoid}
          error={!!validationMessage}
        />
        {validationMessage && <InlineError>{validationMessage}</InlineError>}
      </Form.Field>
      <Button disabled={!!validationMessage} color="purple" type="submit">
        VO ID Speichern
      </Button>
      {user && user.vo_id && (
        <Button onClick={() => hideVauoidModal()}>Abbrechen</Button>
      )}
    </Form>
  );
};

export default VauoidForm;
