import React, { useState, useEffect } from "react";
import { Offer } from "../AppTypes";
import {
  Segment,
  Table,
  Icon,
  Button,
  Header,
  Message,
  Popup,
  Confirm,
} from "semantic-ui-react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import useFetch from "../useFetch";
import { minBy, maxBy } from "lodash";
import axios from 'axios'
import useAuth from "../useAuth";

type SearchResponse = Offer[];

interface IOfferSessionCollectionProps {
  reload: (offerId?: string) => void;
  offers: Offer[];
}

const OfferSessionCollection: React.FunctionComponent<IOfferSessionCollectionProps> =
  ({ offers, reload }) => {
    const [
      setFavouriteOffers,
      setFavouriteLoading,
      setFavouriteError,
      ,
      setSetFavouriteUrl,
      setSetFavouriteError,
    ] = useFetch<SearchResponse, null>({
      method: "POST",
      initialUrl: "",
      initialData: [],
    });

    const [cutResult, cutLoading, cutError, , setCutUrl, setCutError] =
      useFetch<any, null>({
        method: "POST",
        initialUrl: "",
        initialData: {},
      });

    const [
      deleteResult,
      deleteLoading,
      deleteError,
      ,
      setDeleteUrl,
      setDeleteError,
    ] = useFetch<any, null>({
      method: "DELETE",
      initialUrl: "",
      initialData: {},
    });

    const { token } = useAuth()

    const resetErrors = () => {
      setSetFavouriteError("");
      setDeleteError("");
      setCutError("");
    };

    const onSetFavourite = (docId: string) => {
      resetErrors();
      setSetFavouriteUrl(`/api/des/offer/${docId}/favourite?t=${Date.now()}`);
    };

    const onCut = (docId: string) => {
      resetErrors();
      setCutUrl(`/api/des/offer/${docId}/cut?t=${Date.now()}`);
    };

    const onDelete = (docId: string) => {
      resetErrors();
      setDeleteUrl(`/api/des/offer/${docId}?t=${Date.now()}`);
    };

    const [confirmCutModalOpen, setConfirmCutModalOpen] = useState(false);
    const [currentCut, setCurrentCut] = useState<string | null>(null);

    const confirmCut = (docId: string) => {
      setCurrentCut(docId);
      setConfirmCutModalOpen(true);
    };

    const cutOffer = () => {
      if (currentCut) {
        onCut(currentCut);
      }
      setConfirmCutModalOpen(false);
      setCurrentCut(null);
    };

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [currentDelete, setCurrentDelete] = useState<string | null>(null);

    const confirmDelete = (docId: string) => {
      setCurrentDelete(docId);
      setConfirmDeleteModalOpen(true);
    };

    const deleteOffer = () => {
      if (currentDelete) {
        onDelete(currentDelete);
      }
      setConfirmDeleteModalOpen(false);
      // setCurrentDelete(null);
    };

    const onCancel = () => {
      setConfirmCutModalOpen(false);
      setConfirmDeleteModalOpen(false);
      setCurrentCut(null);
      setCurrentDelete(null);
    };

    // Reload all recent offers after invoking an action (favorite, cut, delete)
    useEffect(() => {
      if (currentDelete) {
        reload(currentDelete);
      }
    }, [deleteResult]);

    useEffect(() => {
      if (currentCut) {
        reload(currentCut);
      }
    }, [cutResult]);

    const dep = JSON.stringify(setFavouriteOffers);
    useEffect(() => {
      if (setFavouriteOffers.length > 0) {
        reload();
      }
    }, [dep]);

    // Combined loading state
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      setLoading(setFavouriteLoading || deleteLoading || cutLoading);
    }, [setFavouriteLoading, deleteLoading, cutLoading]);

    // Combined error message state
    const [displayError, setDisplayError] = useState("");
    useEffect(() => {
      setDisplayError(setFavouriteError || deleteError || cutError);
    }, [setFavouriteError, deleteError, cutError]);

    // Calculate session timespan
    const [timespan, setTimespan] = useState("Session");
    useEffect(() => {
      const min = minBy(offers, (o) => new Date(o.date_inserted));
      const max = maxBy(offers, (o) => new Date(o.date_inserted));
      if (min && min?.offer_id === max?.offer_id) {
        setTimespan(
          `Session vom ${format(
            new Date(min.date_inserted),
            "dd.MM.yyyy - HH:mm"
          )} Uhr`
        );
      } else if (min && max) {
        const start = format(new Date(min.date_inserted), "dd.MM.yyyy - HH:mm");
        const sameDate =
          format(new Date(min.date_inserted), "dd.MM.yyyy") ===
          format(new Date(max.date_inserted), "dd.MM.yyyy");
        const end = sameDate
          ? format(new Date(max.date_inserted), "HH:mm")
          : format(new Date(max.date_inserted), "dd.MM.yyyy - HH:mm");
        setTimespan(`Session vom ${start} bis ${end} Uhr`);
      }
    }, [offers]);

    const isFavouriteSet =
      offers.filter((offer) => offer.favourite_offer === 1).length > 0;

    const openPDF = (documentUrl: string, docId: string) => (e: React.MouseEvent) => {
      e.preventDefault();

      // Set jwt token as authorization header
      let headers = {};
      if (token) {
        headers = {
          Authorization: token,
        };
      }
      axios.get(documentUrl, { headers, responseType: 'blob' }).then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a')
        link.href = fileURL;
        link.setAttribute('download', `${docId}.pdf`);
        document.body.appendChild(link);
        link.click();
        // window.open(fileURL);
      });
    };

    return (
      <React.Fragment>
        <Confirm
          open={confirmDeleteModalOpen}
          header="Empfehlung löschen"
          content="Willst Du die Empfehlung wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!"
          onConfirm={deleteOffer}
          onCancel={onCancel}
          confirmButton="Ja, löschen"
          cancelButton="Nein, abbrechen"
          size="small"
        />
        <Confirm
          open={confirmCutModalOpen}
          header="Empfehlung herauslösen"
          content="Willst Du die Empfehlung wirklich aus der Session herauslösen? Die Empfehlung wird dann unter einer neuen und eigenen Session aufgeführt. Diese Aktion kann nicht rückgängig gemacht werden!"
          onConfirm={cutOffer}
          onCancel={onCancel}
          confirmButton="Ja, herauslösen"
          cancelButton="Nein, abbrechen"
          size="small"
        />
        <Header inverted attached="top">
          {timespan}
        </Header>
        <Segment attached loading={loading} clearing>
          {displayError && (
            <Message error>
              Bei der Verarbeitung ist ein Fehler aufgetreten: {displayError}
            </Message>
          )}
          {offers.length > 1 && (
            <React.Fragment>
              Dein Kunde erhält alle in dieser Session gelisteten
              Produktempfehlungen. Wenn Du dies nicht möchtest, kannst Du die
              unerwünschten Empfehlungen entweder aus der Session entfernen (
              {<Icon fitted name="trash" />}) oder herauslösen (
              {<Icon fitted name="cut" />}).
            </React.Fragment>
          )}
          <Table compact basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "13em" }}>
                  Empfehlungs-Code
                </Table.HeaderCell>
                <Table.HeaderCell>gewählte Produkte</Table.HeaderCell>
                {offers.length === 1 && (
                  <Table.HeaderCell collapsing>Aktionen</Table.HeaderCell>
                )}
                {offers.length > 1 && (
                  <React.Fragment>
                    <Table.HeaderCell textAlign="center">
                      Favorit
                    </Table.HeaderCell>
                    <Table.HeaderCell collapsing>Aktionen</Table.HeaderCell>
                  </React.Fragment>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {offers.map((offer) => (
                <Table.Row key={offer.offer_id}>
                  <Table.Cell>
                    <span className="offer-code">{offer.offer_id}</span>
                  </Table.Cell>
                  <Table.Cell>
                    {offer.description.map((d) => d.label).join(", ")}
                  </Table.Cell>
                  {offers.length === 1 && (
                    <Table.Cell>
                      <Popup
                        content="Empfehlungs-Dokument herunterladen"
                        position="top center"
                        trigger={
                          <Button
                            // as="a"
                            // target="_blank"
                            // rel="noreferrer"
                            // download={`${offer.offer_id}.pdf`}
                            title={`${offer.offer_id}.pdf`}
                            // href={`${process.env.REACT_APP_FILE_API}api/files/${offer.offer_id}.pdf`}
                            basic
                            icon="file pdf"
                            circular
                            onClick={openPDF(`${process.env.REACT_APP_FILE_API}api/files/${offer.offer_id}.pdf`, offer.offer_id)}
                          />
                        }
                      />
                    </Table.Cell>
                  )}
                  {offers.length > 1 && (
                    <React.Fragment>
                      <Table.Cell textAlign="center">
                        {offer.favourite_offer === 1 && (
                          <Icon color="green" name="check" />
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Popup
                          content="Empfehlung aus Session herauslösen"
                          position="top center"
                          trigger={
                            <Button
                              basic
                              icon="cut"
                              circular
                              onClick={() => confirmCut(offer.offer_id)}
                            />
                          }
                        />
                        <Popup
                          content="Empfehlung dauerhaft entfernen"
                          position="top center"
                          trigger={
                            <Button
                              basic
                              icon="trash"
                              circular
                              onClick={() => confirmDelete(offer.offer_id)}
                            />
                          }
                        />
                        <Popup
                          content="Empfehlungs-Dokument herunterladen"
                          position="top center"
                          trigger={
                            <Button
                              // as="a"
                              // target="_blank"
                              // rel="noreferrer"
                              // download={`${offer.offer_id}.pdf`}
                              title={`${offer.offer_id}.pdf`}
                              // href={`${process.env.REACT_APP_FILE_API}api/files/${offer.offer_id}.pdf`}
                              basic
                              icon="file pdf"
                              circular
                              onClick={openPDF(`${process.env.REACT_APP_FILE_API}api/files/${offer.offer_id}.pdf`, offer.offer_id)}
                            />
                          }
                        />
                        <Popup
                          content="Empfehlung als Favorit markieren"
                          position="top center"
                          trigger={
                            <Button
                              disabled={offer.favourite_offer === 1}
                              basic
                              icon="favorite"
                              circular
                              onClick={() => onSetFavourite(offer.offer_id)}
                            />
                          }
                        />
                      </Table.Cell>
                    </React.Fragment>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {!isFavouriteSet && offers.length !== 1 && (
            <Message warning>
              Du hast unter dieser Session für einen Kunden mehrere
              Produktempfehlungen generiert. Bevor Du mit der Eingabe der
              Kontaktdaten fortfahren kannst, markiere die Empfehlung (
              {<Icon fitted name="star" />}), bei der Du glaubst, dass sie den
              Kunden am meisten interessiert.
            </Message>
          )}
          <Button
            floated="right"
            color="purple"
            disabled={!isFavouriteSet && offers.length !== 1}
            as={Link}
            to={`/destwo/${isFavouriteSet
              ? offers.filter((offer) => offer.favourite_offer === 1)[0]
                .offer_id
              : offers[0].offer_id
              }`}
          >
            Weiter zur Eingabe der Kontaktdaten
          </Button>
        </Segment>
      </React.Fragment>
    );
  };

export default OfferSessionCollection;
