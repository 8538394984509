import { startsWith } from "lodash";
import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "semantic-ui-less/semantic.less";
import { Button, Modal } from "semantic-ui-react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container/Container";
import "./App.css";
import Navbar from "./components/Navbar";
import VauoidModal from "./components/VauoidModal";
import DatabasePage from "./pages/DatabasePage";
import DesRecommendationDetailPage from "./pages/DesRecommendationDetailPage";
import DestwoPage from "./pages/DestwoPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import useAuth from "./useAuth";
import useGui from "./useGui";

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const prodUrl = "https://lesato.m-dwh.de";
const prodUrlNew = "https://des.dashboard.vodafone.de";

const redirectUrl: string | undefined =
  process.env.NODE_ENV === "production" &&
  (startsWith(window.location.href, prodUrl) ||
    startsWith(window.location.href, prodUrlNew))
    ? process.env.REACT_APP_REDIRECT_PROD
    : process.env.REACT_APP_REDIRECT_TEST;
export const redirectBase: string =
  redirectUrl === undefined ? "/" : redirectUrl;
export const redirect: string = `${redirectBase}/?redirect_url=${window.location.origin}/api/web/auth/sso&appid=lmt`;

const App: React.FunctionComponent = () => {
  const { authenticated, user } = useAuth();
  const { showVauoidModal, appReloadRequested } = useGui();

  // enforce setting of a vauoid on app start
  useEffect(() => {
    if (authenticated && !user?.vo_id) {
      showVauoidModal();
    }
  }, [authenticated]);

  if (!authenticated) {
    return <LoginPage redirect={redirect} />;
  }

  return (
    <React.Fragment>
      <Modal open={appReloadRequested} size="tiny">
        <Modal.Header>Update verfügbar</Modal.Header>
        <Modal.Content>
          Der Server benötigt eine neuere Version dieser Webseite, damit die
          Anwendung ordnungsgemäß funktionieren kann. Lade daher bitte die Seite
          neu.
        </Modal.Content>
        <Modal.Actions>
          <Button color="purple" onClick={() => window.location.reload()}>
            Jetzt aktualisieren
          </Button>
        </Modal.Actions>
      </Modal>
      <Navbar />
      <VauoidModal />
      <Container
        fluid={true}
        style={{ padding: "50px 5px 0px 5px", height: "100%" }}
      >
        <Switch>
          <AuthenticatedRoute
            path="/dashboard"
            exact={true}
            component={HomePage}
            redirect={redirect}
          />
          <AuthenticatedRoute
            path="/destwo"
            exact={true}
            redirect={redirect}
            component={DestwoPage}
          />
          <AuthenticatedRoute
            path="/destwo/:id"
            exact={true}
            redirect={redirect}
            render={({ match }: { match: { params: { id: string } } }) => (
              <DesRecommendationDetailPage id={match.params.id} />
            )}
          />
          <AuthenticatedRoute
            path="/database"
            exact={true}
            redirect={redirect}
            component={DatabasePage}
          />
          <AuthenticatedRoute
            path="/"
            exact={true}
            redirect="/dashboard"
            component={HomePage}
          />
          <Route path="*">
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </Container>
      <Container  textAlign="right">
        <a target="_blank" style={{ textDecoration: 'underline'}} href="https://www.vodafone.de/unternehmen/soziale-verantwortung/datenschutz-privatsphaere.html">Datenschutzhinweise</a>
      </Container>
    </React.Fragment>
  );
};

export default App;
