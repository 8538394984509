import React, { useEffect, useState } from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form/Form";
import FormGroup from "semantic-ui-react/dist/commonjs/collections/Form/FormGroup";
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import Container from "semantic-ui-react/dist/commonjs/elements/Container/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header/Header";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import { Lead } from "../AppTypes";
import LeadsTable from "../components/LeadsTable";
import useFetch from "../useFetch";

interface LeadSearchResponse {
  leads: Lead[];
  count: number;
}

const DEFAULT_LIMIT = 10;

const DatabasePage = () => {
  const [firstname, setFirstname] = useState("");
  const [searchFirstname, setSearchFirstname] = useState("");
  const [name, setName] = useState("");
  const [searchName, setSearchName] = useState("");
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [offset, setOffset] = useState(0);

  const [data, loading, errorMessage, hasLoaded, setUrl] = useFetch<
    LeadSearchResponse,
    null
  >({
    // initialUrl: `/api/des/leads?offset=${offset}&limit=${limit}&firstname=${encodeURIComponent(
    //   searchFirstname
    // )}&name=${encodeURIComponent(searchName)}&t=${Date.now()}`,
    initialUrl: ``,
    initialData: { leads: [], count: 0 },
  });

  useEffect(() => {
    setUrl(
      `/api/des/leads?offset=${offset}&limit=${limit}&firstname=${encodeURIComponent(
        searchFirstname
      )}&name=${encodeURIComponent(searchName)}&t=${Date.now()}`
    );
  }, [searchFirstname, searchName, limit, offset]);

  const onPageChange = (limit: number, offset: number) => {
    setLimit(limit);
    setOffset(offset);
  };

  const onSubmit = () => {
    setOffset(0);
    setLimit(DEFAULT_LIMIT);
    setSearchFirstname(firstname);
    setSearchName(name);
  };

  const onReset = () => {
    setFirstname("");
    setSearchFirstname("");
    setName("");
    setSearchName("");
    setOffset(0);
    setLimit(DEFAULT_LIMIT);
  };

  return (
    <Container>
      <Segment loading={loading}>
        <Header>Suche nach Empfehlungen</Header>
        {errorMessage && <Message error>{errorMessage}</Message>}
        <p className="form-textblock">
          Hier findest Du Empfehlungen mit abgegebenen Beratungseinwilligungen
          aus Deinem Shop. Wenn Du den genauen Namen des Kunden kennst, findest
          Du eine Empfehlung auch wenn diese in einem anderen Shop erstellt
          wurde.
        </p>
        <Form onSubmit={onSubmit}>
          <FormGroup widths="equal">
            <Form.Input
              width={5}
              fluid
              label="Vorname"
              type="text"
              value={firstname}
              onChange={(_, { value }) => {
                setFirstname(value);
              }}
            />
            <Form.Input
              width={5}
              fluid
              label="Nachname"
              type="text"
              value={name}
              onChange={(_, { value }) => {
                setName(value);
              }}
            />
            <Form.Button
              label="&nbsp;"
              width={3}
              fluid
              color="purple"
              content="Suchen"
              icon="search"
              type="submit"
            />
            <Form.Button
              width={3}
              label="&nbsp;"
              fluid
              content="Zurücksetzen"
              icon="undo"
              onClick={onReset}
            />
          </FormGroup>
        </Form>
        {hasLoaded && data.leads.length < 1 && (
          <Message warning>Es wurden keine Datenbankeinträge gefunden</Message>
        )}
        {data.leads.length > 0 && (
          <LeadsTable
            leads={data.leads}
            totalItems={data.count}
            limit={limit}
            offset={offset}
            onPagination={onPageChange}
          />
        )}
      </Segment>
    </Container>
  );
};

export default DatabasePage;
