import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import Form from "semantic-ui-react/dist/commonjs/collections/Form/Form";
import FormGroup from "semantic-ui-react/dist/commonjs/collections/Form/FormGroup";
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Container from "semantic-ui-react/dist/commonjs/elements/Container/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header/Header";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import { Offer } from "../AppTypes";
import OfferSessionCollection from "../components/OfferSessionCollection";
import RecentOffers from "../components/RecentOffers";
import useAuth from "../useAuth";
import useFetch from "../useFetch";
import { without } from "lodash";
import { useCallback } from "react";

type SearchResponse = Offer[];

type RecentOffersResponse = Offer[];

const LATEST_OFFERS_RELOAD_INTERVAL = 10 * 60 * 1000; // 10 Minutes

const DestwoPage: React.SFC = () => {
  const [searchText, setSearchText] = useState("");
  const { user } = useAuth();

  const [offers, loading, errorMessage, , setSearchUrl] = useFetch<
    SearchResponse,
    null
  >({
    initialUrl: "",
    initialData: [],
  });

  const [displayOffers, setDisplayOffers] = useState<Offer[]>([]);
  useEffect(() => {
    setDisplayOffers(offers);
  }, [offers]);

  const [displayError, setDisplayError] = useState("");
  useEffect(() => {
    setDisplayError(errorMessage);
  }, [errorMessage]);

  const [displayLoading, setDisplayLoading] = useState(false);
  useEffect(() => {
    setDisplayLoading(loading);
  }, [loading]);

  const [latestOffers, latestOffersLoading, , , setUrl] = useFetch<
    RecentOffersResponse,
    null
  >({
    initialUrl: `/api/des/offer?vauoid=${user?.vo_id}&t=${Date.now()}`,
    initialData: [],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      reloadRecentOffers();
    }, LATEST_OFFERS_RELOAD_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const reloadRecentOffers = useCallback(() => {
    setUrl(`/api/des/offer?vauoid=${user?.vo_id}&t=${Date.now()}`);
  }, [user?.vo_id]);

  const onSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value.trim());
  };

  const onSubmit = () => {
    setSearchUrl(`/api/des/offer/${searchText}?t=${Date.now()}`);
  };

  // When the item which was searched for gets deleted, clear the textfield and use another recommendation
  // from the same session
  const onDeleteSubmit = (offerId?: string) => {
    if (offerId) {
      setSearchText("");
      // Find an offer_id within session which is not offerId (of the deleted offer)
      const remainingOffers = without(
        offers.map((o) => o.offer_id),
        offerId
      );
      if (remainingOffers.length > 0) {
        setSearchUrl(`/api/des/offer/${remainingOffers[0]}?t=${Date.now()}`);
      }
    } else {
      setSearchUrl(`/api/des/offer/${searchText}?t=${Date.now()}`);
    }
    reloadRecentOffers();
  };

  return (
    <Container>
      <Segment loading={displayLoading}>
        <Header>Suche nach Empfehlungs-Code</Header>
        <p className="form-textblock">
          Hier kannst Du nach Empfehlungen suchen indem du den Empfehlungs-Code
          aus Digital Effective Selling eingibst.
        </p>
        {displayError && <Message error>{displayError}</Message>}
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Form.Field width={12}>
              <Input
                className="offer-code"
                width={200}
                placeholder="Gib den Empfehlungs-Code aus Digital Effective Selling ein..."
                onChange={onSearchTextChange}
                value={searchText}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Button
                disabled={!searchText}
                type="submit"
                color="purple"
                content="Suchen"
                fluid
                icon="search"
              />
            </Form.Field>
          </FormGroup>
        </Form>
        {displayOffers.length > 0 && (
          <OfferSessionCollection
            offers={displayOffers}
            reload={onDeleteSubmit}
          />
        )}
      </Segment>
      <Segment clearing loading={latestOffersLoading}>
        {latestOffers.length > 0 && (
          <RecentOffers offers={latestOffers} onReload={reloadRecentOffers} />
        )}
      </Segment>
    </Container>
  );
};

export default DestwoPage;
