import React, { useEffect } from "react";
import LoadingSpinner from "./LoadingSpinner";

interface Props {
  timeout?: number;
  text?: string;
  redirect: string;
}

const RedirectMessage = ({ text, redirect, timeout = 5000 }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = redirect;
    }, timeout);
  }, []);

  return (
    <LoadingSpinner>
      {text && text}
      {!text && (
        <React.Fragment>
          Du wirst zur Authentifizierung weiter geleitet
        </React.Fragment>
      )}
    </LoadingSpinner>
  );
};

export default RedirectMessage;
