import { useEffect, useState } from "react";
import { REACT_APP_VERSION } from "./App";
import useAuth from "./useAuth";
import compareVersions from "compare-versions";
import useGui from "./useGui";

interface FetchConfig<T, P> {
  method?: "GET" | "POST" | "DELETE";
  payload?: null | P;
  initialUrl: string;
  initialData: T;
}
type FetchReturn<T> = [
  T,
  boolean,
  string,
  boolean,
  (url: string) => void,
  (errorMessage: string) => void
];

const useFetch = <D, P>({
  method = "GET",
  payload = null,
  initialUrl,
  initialData,
}: FetchConfig<D, P>): FetchReturn<D> => {
  const [url, setUrl] = useState(initialUrl);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState<D>(initialData);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { logout, token } = useAuth();
  const { requestReload } = useGui();

  useEffect(() => {
    const run = async () => {
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        // Allways try to add auth header if token is present.
        if (token) {
          headers.append(`Authorization`, token);
        }

        setLoading(true);
        const response = await fetch(url, {
          method,
          headers,
          body: method === "POST" ? JSON.stringify(payload) : undefined,
        });

        // Check if the server explicitly asks for a minimum frontend version of the app
        const requiredVersion =
          response.headers.get("min-lesato-app-version") || REACT_APP_VERSION;
        if (
          requiredVersion &&
          REACT_APP_VERSION &&
          compareVersions.compare(requiredVersion, REACT_APP_VERSION, ">")
        ) {
          console.log(
            `server requesting app version ${requiredVersion} but interface is version ${REACT_APP_VERSION}. Requesting Reload...`
          );
          requestReload();
        }

        // Some custom response error handling
        if (!response.ok) {
          let message = "Fehler: ";
          switch (response.status) {
            case 401:
              logout();
              message += `Nutzer konnte nicht verifiziert werden`;
              break;
            case 404:
              message = `Es wurden keine Daten gefunden`;
              break;
            case 504:
              message += `Der Server braucht zu lange zum Antworten`;
              break;
            default:
              message += `${response.status} (${response.statusText})`;
              break;
          }
          throw new Error(message);
        }
        const data = (await response.json()) as { data: D };
        setData(data.data);
        setLoading(false);
        setErrorMessage("");
        setHasLoaded(true);
      } catch (e) {
        // Todo: log/send error somewhere
        setErrorMessage(e.message);
        setLoading(false);
      }
    };
    // Allow setting an initial empty string for the url to avoid running when calling the hook
    if (url) {
      run();
    }
  }, [url]);

  return [data, loading, errorMessage, hasLoaded, setUrl, setErrorMessage];
};

export default useFetch;
