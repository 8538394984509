import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu/Menu";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image/Image";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import useAuth from "../useAuth";
import useGui from "../useGui";
import VodafoneLogo from "./32.png";
import useApi from "../useApi";

const Navbar: React.SFC = () => {
  const { showVauoidModal } = useGui();
  const { user } = useAuth();
  const { verifyAuth, unauthenticate } = useApi();

  // Verify auth one minute after token should be expired
  useEffect(() => {
    let timeout: any = 0;
    if (user) {
      timeout = setTimeout(() => {
        verifyAuth();
      }, user.exp * 1000 - Date.now() + 60000);
    }

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <Menu
      size="small"
      color="red"
      inverted={true}
      fixed="top"
      className="top-navigation"
    >
      <Menu.Item active={false} as={Link} to="/">
        <Image style={{ marginRight: "10px" }} src={VodafoneLogo} />
        LESATO
      </Menu.Item>
      <Menu.Item as={NavLink} to="/destwo">
        <Icon name="id card" />
        Datenaufnahme
      </Menu.Item>
      <Menu.Item as={NavLink} to="/database">
        <Icon name="search" />
        Datenbank Produktempfehlungen
      </Menu.Item>
      <Menu.Menu position="right">
        <Dropdown
          item={true}
          trigger={
            <span>
              <Icon name="user" />
              Account
            </span>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Header>User</Dropdown.Header>
            <p style={{ color: "black", padding: "0 16px" }}>
              {`${user && user.front_name} ${user && user.last_name}`}
              <br />
              {user && user.email}
            </p>
            <Dropdown.Item
              onClick={unauthenticate}
              icon="user close"
              text="Logout"
            />
            <Dropdown.Divider />
            <Dropdown.Header>RMS ID</Dropdown.Header>
            <p style={{ color: "black", padding: "0 16px" }}>
              {user && user.rmsid}
            </p>
            {user && (
              <React.Fragment>
                <Dropdown.Divider />
                <Dropdown.Header>VO ID</Dropdown.Header>
                <p style={{ color: "black", padding: "0 16px" }}>
                  {user.vo_id ? user.vo_id : "missing"}
                </p>
                <Dropdown.Item
                  onClick={showVauoidModal}
                  icon="write square"
                  text="Anpassen"
                />
              </React.Fragment>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export default Navbar;
