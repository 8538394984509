import { validate as validateEmail } from "email-validator";
import { find, values } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Form from "semantic-ui-react/dist/commonjs/collections/Form/Form";
import FormGroup from "semantic-ui-react/dist/commonjs/collections/Form/FormGroup";
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Container from "semantic-ui-react/dist/commonjs/elements/Container/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header/Header";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import { Offer } from "../AppTypes";
import useAuth from "../useAuth";
import useFetch from "../useFetch";
import { Select, DropdownProps, Modal } from "semantic-ui-react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import format from "date-fns/format";

interface Props {
  id: string;
}

interface FormFields {
  salutation?: string;
  name: string;
  firstname: string;
  email: string;
  mobile: string;
  contact_date: string;
  bid: string;
}

type SetPersonalInfoPayload = FormFields & {
  rmsId: string;
};

type SearchResponse = Offer[];

type Validation = {
  [key in keyof FormFields]?: string;
};

const MONTHS = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];
const WEEKDAYS_LONG = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];
const WEEKDAYS_SHORT = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

const formatDate = (date: Date) => format(date, "dd.MM.yyyy");
const addDays = (date: Date, days: number) =>
  new Date(date.getTime() + 1000 * 3600 * 24 * days);

const defaultFormValues: FormFields = {
  salutation: "false",
  name: "",
  firstname: "",
  email: "",
  mobile: "",
  contact_date: "",
  bid: "",
};

const contactOptions = [{ key: "unselected", value: "", text: "" }];

const DesRecommendationDetailPage: React.SFC<Props> = ({ id }) => {
  const { user } = useAuth();
  switch (user?.saleschannel?.toLowerCase()) {
    case "filiale":
      defaultFormValues.bid = "2554";
      break;
    case "pa":
      defaultFormValues.bid = "2574";
      break;
    case "sonstige":
      defaultFormValues.bid = "9999";
      break;
    default:
      defaultFormValues.bid = "";
      break;
  }

  const [formValues, setFormValues] = useState<FormFields>(defaultFormValues);
  const [validation, setValidation] = useState<Validation>({});

  const [offers, loading, errorMessage] = useFetch<SearchResponse, null>({
    initialUrl: `/api/des/offer/${id}?t=${Date.now()}`,
    initialData: [],
  });

  const [saveSuccess, saveLoading, saveErrorMessage, hasLoaded , setSaveUrl] = useFetch<
    null | boolean,
    SetPersonalInfoPayload
  >({
    method: "POST",
    initialUrl: "",
    initialData: null,
    payload: {
      ...formValues,
      salutation:
        formValues.salutation === "false" ? undefined : formValues.salutation,
      rmsId: user ? user.rmsid : "",
    },
  });

  const [displayLoading, setDisplayLoading] = useState(false);
  useEffect(() => {
    setDisplayLoading(loading);
  }, [loading]);
  useEffect(() => {
    setDisplayLoading(saveLoading);
  }, [saveLoading]);

  const [displayErrorMessage, setDisplayErrorMessage] = useState("");
  useEffect(() => {
    setDisplayErrorMessage(saveErrorMessage);
  }, [saveErrorMessage]);
  useEffect(() => {
    setDisplayErrorMessage(errorMessage);
  }, [errorMessage]);

  const onTextchange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [contactDateOptions, setContactDateOptions] = useState(contactOptions);

  const salutationOptions = [
    { key: "false", value: "false", text: "Bitte wählen" },
    { key: "Frau", value: "Frau", text: "Frau" },
    { key: "Herr", value: "Herr", text: "Herr" },
  ];

  const onDayClick = (date: Date) => {
    // console.log(date);
    if (
      // date is always set midday 12 am, so adjust when comparing current time
      date < new Date(Date.now() - 12 * 60 * 60 * 1000) ||
      date >
        new Date(Date.now() + 60 * 24 * 60 * 60 * 1000 + 12 * 60 * 60 * 1000)
    ) {
      return;
    }
    const dateString = formatDate(date);
    setContactDateOptions((prev) => [
      ...prev,
      { key: dateString, value: dateString, text: dateString },
    ]);
    setFormValues((prev) => ({
      ...prev,
      contact_date: dateString,
    }));
  };

  const onSalutationSelect = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    setFormValues((prev) => ({
      ...prev,
      salutation: value as string,
    }));
  };

  const onContactDateSelect = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    setFormValues((prev) => ({
      ...prev,
      contact_date: value as string,
    }));
  };

  const validate = () => {
    const v: {
      [key in keyof FormFields]?: string;
    } = {};
    if (!formValues.email || formValues.email.length < 1) {
      v["email"] = "Eine E-Mail wird benötigt";
    } else if (formValues.email && !validateEmail(formValues.email)) {
      v["email"] = "Die E-Mail hat kein gültiges Format";
    }
    if (!formValues.name || formValues.name.length < 1) {
      v["name"] = "Eine Nachname wird benötigt";
    }
    if (!formValues.firstname || formValues.firstname.length < 1) {
      v["firstname"] = "Eine Vorname wird benötigt";
    }
    if (!formValues.contact_date || formValues.contact_date.length < 1) {
      v["contact_date"] = "Eine Wunschtermin zur Kontaktaufnahme wird benötigt";
    }
    setValidation(v);
    return !(Object.keys(v).length > 0);
  };

  const onSubmit = () => {
    const valid = validate();
    if (valid) {
      setSaveUrl(`/api/des/offer/${id}/info?t=${Date.now()}`);
    }
  };

  const [offer, setOffer] = useState<Offer | undefined>(undefined);
  useEffect(() => {
    const o = find(offers, ["offer_id", id]);
    if (o) {
      setOffer(o);
    }
  }, [offers]);

  useEffect(() => {
    setContactDateOptions(() => {
      const options = [
        { key: "unselected", value: "", text: "" },
        {
          key: "oneWeek",
          value: formatDate(addDays(new Date(), 7)),
          text: "in einer Woche",
        },
        {
          key: "twoweeks",
          value: formatDate(addDays(new Date(), 14)),
          text: "in 2 Wochen",
        },
        {
          key: "oneMonth",
          value: formatDate(addDays(new Date(), 30)),
          text: "in einem Monat",
        },
      ];
      // avoid option if its 60 days in the future
      if (
        offer?.contract_end_date_fixed &&
        new Date(offer?.contract_end_date_fixed) <
          new Date(Date.now() + 60 * 24 * 60 * 60 * 1000)
      ) {
        options.push({
          key: "fixedContractEnd",
          value: formatDate(new Date(offer.contract_end_date_fixed)),
          text: `Vertragsende Festnetz (${formatDate(
            new Date(offer.contract_end_date_fixed)
          )})`,
        });
      }
      // avoid option if its 60 days in the future
      if (
        offer?.contract_end_date_mobile &&
        new Date(offer?.contract_end_date_mobile) <
          new Date(Date.now() + 60 * 24 * 60 * 60 * 1000)
      ) {
        options.push({
          key: "mobileContractEnd",
          value: formatDate(new Date(offer.contract_end_date_mobile)),
          text: `Vertragsende Mobilfunk (${formatDate(
            new Date(offer.contract_end_date_mobile)
          )})`,
        });
      }
      return options.concat([
        {
          key: "offerAccepted",
          value: "Angebot angenommen",
          text: "Angebot angenommen",
        },
        { key: "none", value: "Keinen", text: "Keinen" },
        {
          key: "selectDate",
          value: "selectDate",
          text: "konkretes Datum auswählen",
        },
      ]);
    });
  }, [offer]);

  const { salutation, name, firstname, email, mobile, contact_date, bid } =
    formValues;

  const errorMessages = values(validation);

  return (
    <Container>
      {hasLoaded === true && (
        <Segment>
          <Header>Empfehlungs-Code: {offer ? offer.offer_id : "None"}</Header>
          <Message success>
            Die Kontaktdaten wurden erfolgreich gespeichert.
          </Message>
          <Button as={Link} to="/destwo" color="purple">
            Zurück zur Suche
          </Button>
        </Segment>
      )}
      {!hasLoaded && (
        <Segment loading={displayLoading}>
          <Header>Empfehlungs-Code: {offer ? offer.offer_id : "None"}</Header>
          <p className="form-textblock">
            Hier kannst du persönliche Kontaktdaten zur gewählten
            Produktempfehlung ergänzen. Der Kunde erhält nachfolgend eine
            Anfrage zur Validierung seiner Emailadresse und zur Bestätigung der
            Einwilligung per Mail. Wurde die BEW erteilt, wird dem Kunden im
            Anschluss die Empfehlung automatisch zugesendet.
          </p>
          {saveSuccess === false && (
            <Message error>
              Beim Speichern ist ein Fehler aufgetreten. Bitte versuche es
              nochmal. Wende Dich an einen Administrator falls das Problem
              weiterhin besteht.
            </Message>
          )}
          {displayErrorMessage && (
            <Message error>{displayErrorMessage}</Message>
          )}
          {errorMessages.length > 0 && (
            <Message error>
              <ul>
                {errorMessages.map((m, i) => (m ? <li key={i}>{m}</li> : ""))}
              </ul>
            </Message>
          )}
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Form.Input label="Anrede" width={4}>
                <Select
                  fluid
                  error={!!validation.salutation}
                  type="select"
                  value={salutation ? salutation : ""}
                  name="salutation"
                  options={salutationOptions}
                  onChange={onSalutationSelect}
                />
              </Form.Input>
              <Form.Input
                error={!!validation.firstname}
                fluid
                width={6}
                label="Vorname*"
                type="text"
                value={firstname ? firstname : ""}
                name="firstname"
                onChange={onTextchange}
              />
              <Form.Input
                error={!!validation.name}
                fluid
                width={6}
                label="Nachname*"
                type="text"
                value={name ? name : ""}
                name="name"
                onChange={onTextchange}
              />
            </FormGroup>
            <FormGroup widths="equal">
              <Form.Input
                error={!!validation.email}
                label="E-Mail*"
                type="text"
                value={email ? email : ""}
                name="email"
                onChange={onTextchange}
              />
              <Form.Input
                error={!!validation.mobile}
                label="Mobil"
                type="text"
                value={mobile ? mobile : ""}
                name="mobile"
                onChange={onTextchange}
              />
            </FormGroup>
            <FormGroup widths="equal">
              <Form.Input label="Wunschtermin zur Kontaktaufnahme*">
                <Select
                  fluid
                  error={!!validation.contact_date}
                  type="select"
                  value={contact_date ? contact_date : ""}
                  name="contact_date"
                  options={contactDateOptions}
                  onChange={onContactDateSelect}
                />
              </Form.Input>
              <Modal open={contact_date === "selectDate"} size="mini">
                <Modal.Content>
                  <DayPicker
                    locale="de"
                    showWeekNumbers
                    months={MONTHS}
                    weekdaysLong={WEEKDAYS_LONG}
                    weekdaysShort={WEEKDAYS_SHORT}
                    firstDayOfWeek={1}
                    onDayClick={onDayClick}
                    disabledDays={[
                      {
                        before: new Date(),
                        after: new Date(Date.now() + 60 * 24 * 60 * 60 * 1000),
                      },
                    ]}
                  />
                </Modal.Content>
              </Modal>
              <Form.Input
                fluid
                label="Store-BID"
                type="text"
                value={bid ? bid : ""}
                name="bid"
                onChange={onTextchange}
              />
            </FormGroup>
            <Button color="purple">Speichern/ Start Mailversand</Button>
          </Form>
        </Segment>
      )}
    </Container>
  );
};

export default DesRecommendationDetailPage;
