import React from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header/Header";
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";
import VoIdForm from "../forms/VoIdForm";
import useApi from "../useApi";
import useGui from "../useGui";

const VauoidModal: React.SFC = () => {
  const { isVauoidModalOpen } = useGui();
  const { error, loading, setVauoid } = useApi();

  return (
    <Modal open={isVauoidModalOpen} size="small" dimmer="blurring">
      <Header>VO ID konfigurieren</Header>
      <Modal.Content>
        {error && <Message error>{error}</Message>}
        <VoIdForm loading={loading} onSubmit={setVauoid} />
      </Modal.Content>
    </Modal>
  );
};

export default VauoidModal;
