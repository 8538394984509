import React from "react";
import { Route, RouteProps } from "react-router-dom";
import RedirectMessage from "../components/RedirectMessage";
import useAuth from "../useAuth";

interface Props extends RouteProps {
  redirect: string;
}

/**
 * Asserts the user is authenticated. If not the user is redirected to the
 * login form.
 *
 * @param isAuthenticated
 * @param Component
 * @param rest
 * @constructor
 */
const AuthenticatedRoute = ({
  redirect,
  component,
  render,
  ...rest
}: Props) => {
  const { authenticated } = useAuth();
  if (!authenticated) {
    return <RedirectMessage redirect={redirect} />;
  } else if (component) {
    return <Route {...rest} component={component} />;
  } else {
    return <Route {...rest} render={render} />;
  }
};

export default AuthenticatedRoute;
