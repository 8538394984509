import { useState } from "react";
import useAuth from "./useAuth";
import useGui from "./useGui";
import { startsWith } from "lodash";

interface SetVauoidResponse {
  statusCode: number;
  user: {
    token: string;
  };
}

const prodUrl = "https://des.dashboard.vodafone.de";

const redirectUrl: string | undefined = startsWith(
  window.location.href,
  prodUrl
)
  ? process.env.REACT_APP_REDIRECT_PROD
  : process.env.REACT_APP_REDIRECT_TEST;
const redirectBase: string = redirectUrl === undefined ? "/" : redirectUrl;

export const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { token, logout, login } = useAuth();
  const { hideVauoidModal } = useGui();

  const _interceptError = (response: Response) => {
    if (!response.ok) {
      let message = "Fehler: ";
      switch (response.status) {
        case 401:
          logout();
          message += `Nutzer konnte nicht verifiziert werden`;
          break;
        case 504:
          message += `Der Server braucht zu lange zum Antworten`;
          break;
        default:
          message += `${response.status} (${response.statusText})`;
          break;
      }
      throw new Error(message);
    }
  };

  const setVauoid = async (vauoid: string) => {
    try {
      setLoading(true);
      setError("");
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      if (token) {
        headers.append(`Authorization`, token);
      }
      const response = await fetch(`/api/web/auth/void?t=${Date.now()}`, {
        method: "POST",
        headers,
        body: JSON.stringify({ vo_id: vauoid }),
      });
      _interceptError(response);
      const data = (await response.json()) as SetVauoidResponse;
      login(data.user.token);
      hideVauoidModal();
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  /**
   * Send an arbitrary request to handle 401 response eventually
   */
  const verifyAuth = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (token) {
      headers.append(`Authorization`, token);
    }
    const response = await fetch(`/api/web/auth/verify?t=${Date.now()}`, {
      headers,
    });
    _interceptError(response);
  };

  const unauthenticate = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (token) {
      headers.append(`Authorization`, token);
    }
    const response = await fetch(`/api/web/auth/logout?t=${Date.now()}`, {
      method: "POST",
      headers,
    });
    _interceptError(response);
    logout();
    window.location.href = `${redirectBase}/logout.aspx`;
  };

  return {
    loading,
    error,
    setVauoid,
    verifyAuth,
    unauthenticate,
  };
};

export default useApi;
