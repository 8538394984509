import React from "react";
import {
  Bar,
  ComposedChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Container, Grid, Header, Message, Segment } from "semantic-ui-react";
import useAuth from "../useAuth";
import useFetch from "../useFetch";

interface OfferStatsMonthly {
  name: string;
  value: number;
  avg: number;
}
type StatsResponse = OfferStatsMonthly[];

const HomePage: React.SFC = () => {
  const { user } = useAuth();

  const [stats, loading, errorMessage] = useFetch<StatsResponse, null>({
    initialUrl: `/api/des/offer-stats-monthly`,
    initialData: [],
  });

  return (
    <Container>
      <Header as="h1">Dashboard</Header>
      <Grid>
        <Grid.Column width={8}>
          <Segment>
            <Header as="h3">
              Hallo {user && user.front_name} {user && user.last_name}
            </Header>
            <p>
              Bitte stellen Sie die Korrektheit der Ihnen zugeordneten
              Eigenschaften sicher:
            </p>
            <p>
              <b>RMS ID:</b> {user && user.rmsid}
            </p>
            <p>
              <b>VO ID:</b> {user && user.vo_id}
            </p>
          </Segment>
          {stats.length > 0 && (
            <Segment loading={loading} style={{ height: 323 }}>
              {errorMessage && <Message negative>{errorMessage}</Message>}
              <Header as="h3">Deine Empfehlungsstatistik</Header>
              <div style={{ width: "100%", height: "250px" }}>
                <ResponsiveContainer>
                  <ComposedChart
                    width={480}
                    height={250}
                    data={stats}
                    margin={{
                      top: 10,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 10" /> */}
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend
                      payload={[
                        {
                          value: "Anzahl erstellte Empfehlungen",
                          id: "pv",
                          color: "#e30613",
                        },
                        {
                          value: `Durchschnitt: ${stats[0].avg} je Monat`,
                          id: "pv",
                          color: "#000000",
                        },
                      ]}
                    />
                    <Bar
                      name="Anzahl"
                      dataKey="value"
                      barSize={35}
                      fill="#e30613"
                    />
                    <ReferenceLine
                      y={stats[0].avg}
                      strokeWidth={1.5}
                      stroke="#2c0b0d"
                      strokeDasharray="6 12"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </Segment>
          )}
        </Grid.Column>
        <Grid.Column width={8}>
          <Segment>
            <Header as="h3">Tool Beschreibung</Header>
            <p>
              Das Lead Sales Tool ermöglicht Dir, wichtige Kundendaten zu deiner
              erstellten Produktempfehlung aus dem Digital Effective Selling
              (DES 2.0) aufzunehmen. Du kannst dem Kunden über diesem Weg die
              Produktempfehlung per Mail zusenden. Erteilt der Kunde eine
              Beratungseinwilligung, können wir Dir den Kunden entweder zu
              seinem wechselbereiten Termin oder zu einem vereinbarten Zeitpunkt
              in Elsbeth als Kampagne zur Verfügung stellen. Ist der Kunde
              wieder bei dir im Shop, kannst du in die Datenbank alle wichtigen
              Kundeninfos einsehen und dem Kunden eine passende Empfehlung
              unterbreiten.
            </p>
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default HomePage;
