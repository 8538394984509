import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table/Table";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import { Lead } from "../AppTypes";
import { Pagination, PaginationProps } from "semantic-ui-react";
import axios from 'axios'
import useAuth from "../useAuth";

interface Props {
  leads: Lead[];
  totalItems: number;
  limit: number;
  offset: number;
  onPagination: (limit: number, offset: number) => void;
}

const LeadsTable: React.SFC<Props> = ({
  leads,
  limit,
  offset,
  onPagination,
  totalItems,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (_: any, { activePage }: PaginationProps) => {
    let page = 1;
    if (typeof activePage === "string") {
      page = parseInt(activePage);
    } else if (typeof activePage === "number") {
      page = activePage;
    }
    setCurrentPage(page);
  };

  const { token } = useAuth()

  useEffect(() => {
    onPagination(limit, (currentPage - 1) * limit);
  }, [currentPage]);

  const openPDF = (documentUrl: string, docId: string) => (e: React.MouseEvent) => {
    e.preventDefault();

    // Set jwt token as authorization header
    let headers = {};
    if (token) {
      headers = {
        Authorization: token,
      };
    }
    axios.get(documentUrl, { headers, responseType: 'blob' }).then((res) => {
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a')
      link.href = fileURL;
      link.setAttribute('download', `${docId}.pdf`);
      document.body.appendChild(link);
      link.click();
      // window.open(fileURL);
    });
  };

  return (
    <React.Fragment>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Datum</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>E-Mail</Table.HeaderCell>
            <Table.HeaderCell>gewählte Produkte</Table.HeaderCell>
            <Table.HeaderCell>Empfehlungs-Dokument</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {leads.map((r) => (
            <Table.Row key={r.ds_id}>
              <Table.Cell>
                {format(new Date(r.date_inserted), "dd.MM.yyyy")}
              </Table.Cell>
              <Table.Cell>{`${r.firstname ? r.firstname : ""} ${
                r.name ? r.name : ""
              }`}</Table.Cell>
              <Table.Cell>{r.email}</Table.Cell>
              <Table.Cell>
                {r.description.map((d) => d.label).join(", ")}
              </Table.Cell>
              <Table.Cell>
                <Icon name="file" />
                <a
                  target="_blank"
                  rel="noreferrer"
                  // download={`${r.offer_id}.pdf`}
                  title={`${r.offer_id}.pdf`}
                  onClick={openPDF(`${process.env.REACT_APP_FILE_API}api/files/${r.offer_id}.pdf`, r.offer_id)}
                  // href={`${process.env.REACT_APP_FILE_API}api/files/${r.offer_id}.pdf`}
                >{`${r.offer_id}.pdf`}</a>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination
        activePage={Math.floor((offset + limit) / limit)}
        totalPages={Math.ceil(totalItems / limit)}
        firstItem={null}
        lastItem={null}
        // defaultActivePage={1}
        siblingRange={3}
        boundaryRange={2}
        onPageChange={onPageChange}
      />
    </React.Fragment>
  );
};

export default LeadsTable;
