import { useContext } from "react";
import { AppContext } from "./AppContext";

const useGui = () => {
  const [state, setState] = useContext(AppContext);

  const showVauoidModal = () => {
    setState((prevState) => ({
      ...prevState,
      gui: {
        ...prevState.gui,
        showVauoidModal: true,
      },
    }));
  };

  const hideVauoidModal = () => {
    setState((prevState) => ({
      ...prevState,
      gui: {
        ...prevState.gui,
        showVauoidModal: false,
      },
    }));
  };

  const requestReload = () => {
    setState((prevState) => ({
      ...prevState,
      gui: {
        ...prevState.gui,
        appReloadRequested: true,
      },
    }));
  };

  return {
    appReloadRequested: state.gui.appReloadRequested,
    isVauoidModalOpen: state.gui.showVauoidModal,
    showVauoidModal,
    hideVauoidModal,
    requestReload,
  };
};

export default useGui;
